import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { differenceInDays, formatDistance, format } from "date-fns";
import styled from "@emotion/styled";
import tw from "twin.macro";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";
import PortableText from "./portableText";
// import Newsletter from "../Form/Newsletter";
import BlogPostPreview from "./BlogPostPreview";
import CallToAction from "../Repeating/CTA";
import ButtonWithUnderline from "../Button/ButtonWithUnderline";

const StyledContent = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  li {
    ${tw`text-gray-800`}
  }
  p {
    ${tw`mb-4`}
  }
  ul {
    ${tw`list-disc pl-7 mb-4 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  a {
    ${tw`text-primary-600`}
  }
`;

function BlogPost(props) {
  const {
    siteMetadata,
    next,
    prev,
    slug,
    _rawBody,
    author,
    categories,
    title,
    featuredImage,
    publishedAt,
  } = props;

  const data = useStaticQuery(graphql`
    {
      posts: allSanityPost(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const currentPostCategory =
    categories.length > 0 && categories.slice(0, 1).map((category) => category.title);

  let postNodes = [];

  categories.length > 0
    ? (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
            .filter((items) => items.categories.find((item) => item.title == currentPostCategory))
        : [])
    : (postNodes = (data || {}).posts
        ? mapEdgesToNodes(data.posts)
            .filter(filterOutDocsWithoutSlugs)
            .filter(filterOutDocsPublishedInTheFuture)
        : []);

  return (
    <>
      <div className="container">
        <article className="pt-12 md:pt-20 pb-20 md:pb-32 bg-white px-6 md:px-14 lg:px-22">
          <header className="mb-10 md:mb-18 md:text-center">
            <h1 className="text-gray-800 heading-two">{title}</h1>
          </header>

          <div className="flex justify-between items-end mb-8 md:mb-10">
            <div className="flex">
              {author && author.image && (
                <div className="mr-3">
                  <GatsbyImage
                    image={author.image.asset.gatsbyImageData}
                    className="w-12 h-12 rounded-full z-0"
                  />
                </div>
              )}

              <div>
                {/* <div className="text-secondary-50 font-body font-bold text-sm flex items-center space-x-1 mb-1">
                    <span>By</span>
                    {author && (
                      <div>
                        <ul>
                          <li>{author.name}</li>
                        </ul>
                      </div>
                    )}
                  </div> */}

                {categories.length > 0 && (
                  <ul className="flex">
                    {categories.map((category, i) => (
                      <li className="font-body text-primary-600 tracking-wide uppercase" key={i}>
                        {i > 0 ? `, ${category.title}` : category.title}
                      </li>
                    ))}
                  </ul>
                )}

                {publishedAt && (
                  <div className="text-sm text-gray-900 font-normal">
                    {differenceInDays(new Date(publishedAt), new Date()) > 3
                      ? formatDistance(new Date(publishedAt), new Date())
                      : format(new Date(publishedAt), "MMMM d, yyyy")}
                  </div>
                )}
              </div>
            </div>

            <div className="hidden md:flex items-center space-x-7">
              <FacebookShareButton
                url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
                quote={title}
              >
                <i className="fab fa-facebook-f text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
              </FacebookShareButton>

              <LinkedinShareButton
                url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
                title={title}
              >
                <i className="fab fa-linkedin-in text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
              </LinkedinShareButton>

              <TwitterShareButton
                url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
                title={title}
              >
                <i className="fab fa-twitter text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
              </TwitterShareButton>

              <EmailShareButton
                url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
                subject={title}
              >
                <i className="fas fa-envelope text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
              </EmailShareButton>
            </div>
          </div>

          {featuredImage && featuredImage.asset && (
            <div className="mb-12 md:mb-20">
              <GatsbyImage image={props.featuredImage.asset.gatsbyImageData} />
            </div>
          )}

          <StyledContent>{_rawBody && <PortableText blocks={_rawBody} />}</StyledContent>

          {/* <div className="flex items-center justify-center space-x-7 mb-20 md:mb-30">
            <FacebookShareButton
              url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
              quote={title}
            >
              <i className="fab fa-facebook-f text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
            </FacebookShareButton>

            <LinkedinShareButton
              url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
              title={title}
            >
              <i className="fab fa-linkedin-in text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
            </LinkedinShareButton>

            <TwitterShareButton
              url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
              title={title}
            >
              <i className="fab fa-twitter text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
            </TwitterShareButton>

            <EmailShareButton
              url={`${siteMetadata.siteUrl}/blog/${slug.current}/`}
              subject={title}
            >
              <i className="fas fa-envelope text-primary-600 hover:text-gray-900 text-xl transition-colors duration-300 ease-linear"></i>
            </EmailShareButton>
          </div> */}

          <div className="flex justify-between mt-16">
            {prev ? (
              <AniLink
                fade
                to={`/blog/${prev.slug.current}/`}
                className="group font-bold inline-flex items-center justify-center space-x-2.5 text-primary-600 hover:text-primary-600"
              >
                <i className="far fa-arrow-left text-xl relative right-0 group-hover:right-1.5 transition-all duration-300 ease-linear"></i>{" "}
                <span>Previous</span>
              </AniLink>
            ) : (
              <div></div>
            )}
            {next ? (
              <AniLink
                fade
                to={`/blog/${next.slug.current}/`}
                className="group font-bold inline-flex items-center justify-center space-x-2.5 text-primary-600 hover:text-primary-600"
              >
                <span>Next</span>
                <i className="far fa-arrow-right text-xl relative left-0 group-hover:left-1.5 transition-all duration-300 ease-linear"></i>
              </AniLink>
            ) : (
              <div></div>
            )}
          </div>
        </article>
      </div>

      <section className="pb-20 md:pb-32 pt-24 md:pt-40">
        <div className="container">
          <div className="flex items-center justify-between mb-8 md:mb-10">
            <h2 className="mb-0">Related Articles</h2>
            <ButtonWithUnderline
              href="/blog/"
              text="Read All Articles"
              className="hidden md:inline-flex"
            />
          </div>

          {postNodes && postNodes.length > 0 && (
            <div className="mb-10 md:mb-0">
              {postNodes.slice(0, 2).map((node) => (
                <div key={node.id}>
                  <BlogPostPreview {...node} isInList />
                </div>
              ))}
            </div>
          )}

          <ButtonWithUnderline href="/blog/" text="Read All Articles" className="md:hidden" />
        </div>
      </section>

      <CallToAction />
    </>
  );
}

export default BlogPost;
